import {
  IconBathroom,
  IconBedroom,
  IconKitchen,
  IconLivingRoom,
} from '@buydepa-public/ui';
import { subYears } from 'date-fns';

export const API_KEY_GOOGLE_MAPS = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;
export const SANTIAGO_COORDINATES = { lat: -33.4509747, lng: -70.6567212 };
export const GOOGLE_MAPS_MARKER_COLOR_URLS = {
  primary: '/marker.svg',
  secondary: '/marker-secondary.svg',
};
export const DEFAULT_GOOGLE_MAPS_ZOOM = 12;
export const CACHE_CONTROL = {
  COMMUNE_CAPITAL_GAIN_DATA: 259200, // 3 days
  UF_PRICE: 10800, // 3 hours
  FPAY: 300, // 2 hours
};
export const DEFAULT_VALUE_FPAY = '1.020.854.714';
export const MAX_FILE_SIZE = 10000000;
// Others
const ADULT_AGE = 18;
const ELDER_AGE = 80;
export const MAX_DATE_CREDIT_FORM = subYears(new Date(), ADULT_AGE);
export const MIN_DATE_CREDIT_FORM = subYears(new Date(), ELDER_AGE);
export const MIN_DATE_SCHEDULE_FORM = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate() + 1,
);
export const MAX_DATE_SCHEDULE_FORM = new Date(
  new Date().getFullYear(),
  new Date().getMonth() + 1,
  new Date().getDate(),
);
export const MONTH_NAMES = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

export const DEFAULT_PASE_SIZE = 1000;

export const COLOMBIA_INTERNAL_RECIPIENTS =
  process.env.NOTIFICATIONS_API_COL_INTERNAL_RECIPIENTS;

export const MEXICO_INTERNAL_RECIPIENTS =
  process.env.NOTIFICATIONS_API_MEX_INTERNAL_RECIPIENTS;

export const DEFAULT_QUOTATION_INTERNAL_RECIPIENT =
  process.env.NOTIFICATIONS_API_INTERNAL_RECIPIENT;

export const COMPLAIN_CHANNEL_EMAIL_RECIPIENT =
  process.env.COMPLAIN_CHANNEL_EMAIL_RECIPIENT;

export const REGEX_EMAIL =
  /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;

export const REGEX_PHONE_NUMBER = /^[0-9]{8}$/;
export const REGEX_PHONE_NUMBER_COL = /^[0-9]*$/;

export const NEW_BUYDEPA_COLOR = '#3A5AFF';

export const REGEX_ADDRESS = /(.*)\s(\d){1,5}/i;

export const WEB_SCHEDULER_START_DATE = new Date();

export const WEB_SCHEDULER_MIN_DATE = new Date();

export const PRE_SALE_BROKER_PURCHASING_STATUS_OPTIONS = [
  'Cierre y Copia',
  'Cerrada para ingreso a CBR',
];

export const STOCK_BROKER_PURCHASING_STATUS_OPTIONS = [
  'Proceso de Inscripcion',
  'Inscripcion Finalizada',
  'Informe Final',
  'Acta de Entrega',
  'Proceso Finalizado',
];

export const BROKER_PROPERTIES_STATUS_OPTIONS = [
  'Stock',
  'Pre venta',
  'Pre venta recibido',
];

export const LIST_FURNISHED = [
  'Cocina a gas',
  'Lavadora',
  'Refrigerador',
  'Cama de 2 plazas + respaldo',
  'Microondas',
  'Veladores',
];

export const AVAILABLE_PURCHASING_STATUSES = [
  'Cierre y Copia',
  'Cerrada para ingreso a CBR',
  'Proceso de Inscripcion',
  'Inscripcion Finalizada',
  'Informe Final',
  'Acta de Entrega',
  'Proceso Finalizado',
];

export const AVAILABLE_SELLING_STATUSES = ['Disponible'];

export const MAX_PAGE_INDEX_FOR_SELECTION = 5;

export const COMMUNE_OPTIONS: string[] = [
  'Santiago',
  'Estacion Central',
  'La Cisterna',
  'La Florida',
  'San Miguel',
  'Quinta Normal',
];
export const BATHROOM_OPTIONS: number[] = [1, 2, 3];
export const BEDROOM_OPTIONS: number[] = [1, 2, 3];
export const ANTIQUITY_YEAR_HELPER = 1000;
export const PURCHASE_OPERATIONS_STATUS = [
  {
    label: 'Documentos enviados por mi',
    type: 'DOCUMENTOS ENVIADOS POR MI',
    value: 'Documentos enviados por mi',
    href: 'background',
  },
  {
    label: 'Antecedentes',
    type: 'ANTECEDENTES',
    value: 'Antecedentes',
    href: 'background',
  },
  {
    label: 'Estudio de título',
    type: 'ESTUDIO DE TÍTULO',
    value: 'Titulo',
    href: 'title',
  },
  {
    label: 'Notaría y firma',
    type: 'NOTARIA Y FIRMA',
    value: 'Notaría',
    href: 'notary',
  },
  {
    label: 'Inscripción y entrega',
    type: 'INSCRIPCIÓN Y ENTREGA',
    value: 'Inscripción',
    href: 'registration',
  },
];

export const REPORT_RELATIONSHIP_OPTIONS = [
  'Cliente',
  'Inversionista',
  'Broker',
  'Trabajador',
  'Sin relación',
];

export const TYPES_FILES = [
  { label: 'Otros', value: 'Otro' },
  {
    label: 'Contrato de arriendo',
    value: 'ContratoDeArriendo',
  },
  {
    label: 'Copia del reglamento del Fondo de Inversión',
    value: 'CopiaReglamentoFondoInversion',
  },
  { label: 'Copia cédula de identidad', value: 'CopiaCedulaDeIdentidad' },
  { label: 'Escritura de compra venta', value: 'EscrituraDeCompraVenta' },
  {
    label: 'Certificado de dominio vigente',
    value: 'CertificadoDeDominioVigente',
  },
  {
    label: 'Certificado de hipoteca y gravamenes',
    value: 'CertificadoDeHipotecaYGravamenes',
  },
  { label: 'Certificado de avalúo fiscal', value: 'CertificadoDeAvaluoFiscal' },
  {
    label: 'Certificado de contribuciones',
    value: 'CertificadoDeContribuciones',
  },
  {
    label: 'Certificado de estacionamiento',
    value: 'CertificadoDeEstacionamiento',
  },
  { label: 'Certificado de bodega', value: 'CertificadoDeBodega' },
  {
    label: 'Certificado de derechos de aseo',
    value: 'CertificadoDeDerechosDeAseo',
  },
  {
    label: 'Certificado de no expropiación municipal',
    value: 'CertificadoDeNoExpropiacionMunicipal',
  },
  {
    label: 'Certificado de no expropiación serviu',
    value: 'CertificadoDeNoExpropiacionServiu',
  },
  { label: 'Certificado de estado civil', value: 'CertificadoDeEstadoCivil' },
  { label: 'Título reparado', value: 'TituloReparado' },
  { label: 'Título subsanado', value: 'TituloSubsanado' },
  { label: 'Informe de títulos', value: 'InformeDeTitulos' },
  {
    label: 'Confección de borrador de escritura de compraventa',
    value: 'BorradorDeEscrituraLegaltec',
  },
  {
    label: 'Confección de instrucciones notariales',
    value: 'BorradorDeInstruccionesLegaltec',
  },
  { label: 'Borrador de acta de entrega', value: 'BorradorDeActaDeEntrega' },
  {
    label: 'Borrador de esctiura correcciones buydepa',
    value: 'BorradorDeEscrituraCorreccionesBuydepa',
  },
  {
    label: 'Borrador de acta de entrega correciones buydepa',
    value: 'BorradorDeActaDeEntregaCorreccionesBuydepa',
  },
  {
    label: 'Borrador de escritura versión final',
    value: 'BorradorDeEscrituraVersionFinal',
  },
  {
    label: 'Borrador de instrucciones versional final',
    value: 'BorradorDeInstruccionesVersionFinal',
  },
  {
    label: 'Borrador de actca de entrega version final',
    value: 'BorradorDeActaDeEntregaVersionFinal',
  },
  { label: 'GP', value: 'GP' },
  { label: 'Ficha UAF', value: 'FichaUAF' },
  {
    label: 'Liquidación de prepago crédito hipotecario',
    value: 'LiquidacionDePrepagoCreditoHipotecario',
  },
  { label: 'Escritura matrizada', value: 'EscrituraMatrizada' },
  { label: 'Solicitud vale vistas', value: 'SolicitudValeVistas' },
  { label: 'Vale vistas emitidos', value: 'ValeVistasEmitidos' },
  { label: 'Liquidación Banco', value: 'LiquidacionBanco' },
  {
    label: 'Póliza de seguro incéndio y sismos',
    value: 'PolizaDeSeguroIncendioYSismos',
  },
  {
    label: 'Certificado de inscripciones',
    value: 'CertificadoDeInscripciones',
  },
  { label: 'Inscripción hipoteca', value: 'InscripcionHipoteca' },
  { label: 'Inscripción prohibición', value: 'InscripcionProhibicion' },
  { label: 'Certificado cancelación', value: 'CertificadoCancelacion' },
  { label: 'GP actualizado', value: 'GPActualizado' },
  { label: 'Acta de entrega', value: 'ActaDeEntrega' },
  {
    label: 'Certificado no deuda pensión alimentos',
    value: 'CertificadoNoDeudaPensionAlimentos',
  },
  {
    label: 'Borrador de instrucciones',
    value: 'BorradorDeInstrucciones',
  },
  {
    label: 'Copia escritura constitución sociedad',
    value: 'CopiaEscrituraConstitucionSociedad',
  },
  {
    label: 'Copias modificación estatutos sociedad',
    value: 'CopiaModificacionEstatutosSociedad',
  },
  {
    label: 'Copias escritura representantes sociedad',
    value: 'CopiaEscrituraRepresentantesSociedad',
  },
  {
    label: 'Copia inscripción estatutos',
    value: 'CopiaInscripcionEstatutos',
  },
  {
    label: 'Copia publicación sociedad Diario Oficial',
    value: 'CopiaPublicacionSociedadDiarioOficial',
  },
  {
    label: 'Fotocopia tarjetas RUT sociedad',
    value: 'FotocopiaTarjetasRutSociedad',
  },
  {
    label: 'Contrato de arriendo',
    value: 'ContratoDeArriendo',
  },
  {
    label: 'Copia del reglamento del Fondo de Inversión',
    value: 'CopiaReglamentoFondoInversion',
  },
  {
    label: 'Version final escritura de compra venta',
    value: 'VersionFinalEscrituraDeCompraVenta',
  },
  {
    label: 'Version final borradorinstrucciones notariales',
    value: 'VersionFinalBorradorInstruccionesNotariales',
  },
  {
    label: 'Documentos disponibles para firma',
    value: 'DocumentosDisponiblesParaFirma',
  },
  {
    label: 'Documentos firmados',
    value: 'DocumentosFirmados',
  },
  {
    label: 'Escritura cerrada por Notaría',
    value: 'EscrituraCerradaPorNotaria',
  },
  {
    label: 'Entrega de propiedad y liberación de fondos*',
    value: 'EntregaDePropiedadYLiberacionDeFondos',
  },
  {
    label: 'Ingreso a inscripción en CBR',
    value: 'IngresaAInscripcionEnCBR',
  },
  {
    label: 'Compraventa inscrita en CBR',
    value: 'CompraventaInscritaEnCBR',
  },
  {
    label: 'Informe final de la transacción',
    value: 'InformeFinalDeLaTransaccion',
  },
];

export const CIVIL_STATUS = [
  {
    value: 'Soltero',
    label: 'Soltero',
  },
  {
    value: 'Viudo',
    label: 'Viudo',
  },
  {
    value: 'Separado',
    label: 'Separado',
  },
  {
    value: 'CasadoSociedadConyugal',
    label: 'Casado Sociedad Conyugal',
  },
  {
    value: 'CasadoSeparacionBienes',
    label: 'Casado Separaciones Bienes',
  },
  {
    value: 'CasadoSeparacionGananciales',
    label: 'Casado Separación Gananciales',
  },
];

export const DIRECTORY = [
  {
    photo: '/images/nicolas-mendez.png',
    name: 'Nicolás Méndez',
    role: 'Co-founder & CEO',
    info: '9 años liderando equipos de alto rendimiento en el mercado de capitales de LATAM.',
  },
  {
    photo: '/images/pablo-ruiz-tagle.png',
    name: 'Pablo Ruiz-Tagle',
    role: 'Co-Founder',
    info: 'Pionero en la industria del Multifamily en Chile, desarrolló y vendió sobre 350M USD',
  },
  {
    photo: '/images/antonio-roa.png',
    name: 'Antonio Roa',
    role: 'Co-Founder',
    info: 'Emprendedor en serie con foco en rubro inmobiliario, ha creado 4 empresas de alto rendimiento en los últimos 5 años.',
  },
  {
    photo: '/images/ignacio-canal.png',
    name: 'Ignacio Canal',
    role: 'Inversionista y Director',
    info: 'Fundador de Migrante, Lemontech, Instagis y director de emprendimientos',
  },
  {
    photo: '/images/jose-anotnio-berrios.png',
    name: 'José Antonio Berrios',
    role: 'Director',
    info: 'Fundador de Broota y director de startups',
  },
  {
    photo: '/images/brian-thornton.png',
    name: 'Brian Thornton',
    role: 'Director',
    info: 'Especialista en financiamiento estructurado en sectores de energía, inmobiliario e infraestructura.',
  },
];
export const TEAM = [
  {
    nationality: 'chilean',
    photo: '/images/team/agustin-ruiz-tagle-picture.png',
    name: 'Agustín Ruiz-Tagle',
    role: 'Country Manager',
    linkedin:
      'https://www.linkedin.com/in/agustin-ruiz-tagle-cruzat-41768616b/',
    interests: ['sports', 'music', 'golf', 'tv'],
  },
  {
    nationality: 'chilean',
    photo: '/images/team/ale-flores-picture.png',
    name: 'Alejandra Flores',
    role: 'Líder Operaciones Compra',
    linkedin:
      'https://www.linkedin.com/in/alejandro-gaviria-jaramillo-67b77046/',
    interests: ['book', 'art', 'family', 'pasta'],
  },
  {
    nationality: 'chilean',
    photo: '/images/team/andrea-parra-picture.png',
    name: 'Andrea Parra',
    role: 'Asist. de Admin. y Operaciones',
    linkedin: 'https://www.linkedin.com/in/andrea-parra-289074160/',
    interests: ['tv', 'art', 'pasta', 'walk'],
  },
  {
    nationality: 'chilean',
    photo: '/images/team/andres-torres-picture.png',
    name: 'Andres Torres',
    role: 'Chief Operation Officer',
    linkedin: 'https://www.linkedin.com/in/andrestorrespinto/',
    interests: ['tennis_ball', 'motorcycle', 'pasta'],
  },

  {
    nationality: 'chilean',
    photo: '/images/team/angelica-martinez-picture.png',
    name: 'Angélica Martínez',
    role: 'Analista de Contabilidad',
    linkedin:
      'https://www.linkedin.com/in/mar%C3%ADa-ang%C3%A9lica-mart%C3%ADnez-alarc%C3%B3n-822a2675/',
    interests: ['tv', 'book', 'chef', 'gym'],
  },
  {
    nationality: 'chilean',
    photo: '/images/team/carlos_vega-picture.png',
    name: 'Carlos Vega',
    role: 'Arquitecto de Software',
    linkedin:
      'https://www.linkedin.com/in/carlos-eduardo-vega-hern%C3%A1ndez-8b764b37/',
    interests: ['videogames', 'martial_arts', 'gym', 'developer'],
  },
  {
    nationality: 'chilean',
    photo: '/images/team/diego-gallegos-picture.png',
    name: 'Diego Gallegos',
    role: 'Analista Op. Comerciales',
    linkedin: 'https://www.linkedin.com/in/diego-gallegos-flores-482224207/',
    interests: ['bike', 'music', 'cinema', 'lab'],
  },
  {
    nationality: 'chilean',
    photo: '/images/team/greisbely-mendoza-picture.png',
    name: 'Greisbely Mendoza ',
    role: 'Asistente de Administración',
    linkedin: '',
    interests: ['tv', 'french_fries', 'dance', 'swim'],
  },
  {
    nationality: 'chilean',
    photo: '/images/team/horacio-garay-picture.png',
    name: 'Horacio Adolfo Garay',
    role: 'Chief Marketing Officer',
    linkedin: 'https://www.linkedin.com/in/horacio-garay/',
    interests: ['tv', 'music', 'data', 'book'],
  },
  {
    nationality: 'chilean',
    photo: '/images/team/juan-armando-calvo-picture.png',
    name: 'Juan Armando Calvo',
    role: 'Asesor de Inversiones ',
    linkedin: 'https://www.linkedin.com/in/juan-armando-calvo-lira-569523187/',
    interests: ['book', 'run', 'tv'],
  },
  {
    nationality: 'chilean',
    photo: '/images/team/jose-ignacio-gonzalez-picture.png',
    name: 'José Ignacio Gonzalez',
    role: 'Key Account Manager',
    linkedin:
      'https://www.linkedin.com/in/jos%C3%A9-ignacio-gonz%C3%A1lez-riveros-962a59104/',
    interests: ['car', 'motorcycle', 'mountain'],
  },

  {
    nationality: 'chilean',
    photo: '/images/team/karina-pincheira-picture.png',
    name: 'Karina Pincheira',
    role: 'Encargada del Área Créditos',
    linkedin: 'https://www.linkedin.com/in/karina-pincheira-figueroa-8a43ab60/',
    interests: ['ticket', 'hands', 'book', 'pets'],
  },
  {
    nationality: 'chilean',
    photo: '/images/team/manuel-gualda-picture.png',
    name: 'Manuel Gualda',
    role: 'Product Owner',
    linkedin:
      'https://www.linkedin.com/in/jos%C3%A9-manuel-gualda-barriga-832802201/',
    interests: ['friends', 'music', 'tv', 'football'],
  },

  {
    nationality: 'chilean',
    photo: '/images/team/maria-de-bourguignon-picture.png',
    name: 'Maria De Bourguignon',
    role: 'Fotógrafa',
    linkedin:
      'https://www.linkedin.com/in/mar%C3%ADa-ignacia-de-bourguignon-27a27221a/',
    interests: ['gym', 'travel', 'mountain', 'photos'],
  },

  {
    nationality: 'chilean',
    photo: '/images/team/martin-ruiz-tagle-picture.png',
    name: 'Martin Ruiz-Tagle',
    role: 'Fullstack Developer',
    linkedin: 'https://www.linkedin.com/in/mart%C3%ADn-ruiz-tagle-938a50190/',
    interests: ['sports', 'friends', 'music', 'beer'],
  },
  {
    nationality: 'chilean',
    photo: '/images/team/matthew-escudero-picture.png',
    name: 'Matthew Escudero',
    role: 'Inspector Técnico de Obra',
    linkedin: 'https://www.linkedin.com/in/matthew-escudero-ibaceta-883805140',
    interests: ['friends', 'music', 'tv', 'football'],
  },

  {
    nationality: 'chilean',
    photo: '/images/team/pablo-carreno-picture.png',
    name: 'Pablo Carreño',
    role: 'Líder de Finanzas',
    linkedin: '',
    interests: ['french_fries', 'music', 'pets', 'cake'],
  },
  {
    nationality: 'chilean',
    photo: '/images/team/sebastian_martinez-picture.png',
    name: 'Sebastian Martinez',
    role: 'Analista de Adquisiciones',
    linkedin: 'https://www.linkedin.com/in/sebastian-martinez-bertrand/',
    interests: ['cinema', 'basket', 'music', 'photos'],
  },
  {
    nationality: 'chilean',
    photo: '/images/team/moises-solano-picture.png',
    name: 'Moises Lozano',
    role: 'Fotógrafo',
    linkedin: '',
    interests: ['cinema', 'tv', 'music', 'photos'],
  },
  {
    nationality: 'chilean',
    photo: '/images/team/camila-leal-picture.png',
    name: 'Camila Leal',
    role: 'Contact Executive',
    linkedin: '',
    interests: ['gym', 'football', 'cake'],
  },
  {
    nationality: 'chilean',
    photo: '/images/team/maria-jesus-gonzalez-picture.png',
    name: 'María Jesús González',
    role: 'Stock Manager',
    linkedin: '',
    interests: ['gym', 'football', 'cake'],
  },
];

export const VERCEL_COUNTRY_HEADER_KEY = 'X-Vercel-IP-Country';

export const COUNTRY_CONFIGS: Record<
  string,
  {
    href: string;
    code: string;
    navBarTitle: string;
    countryFilterValue: string;
    selected: boolean;
    showCapitalGain?: boolean;
    showDividendCalculator?: boolean;
    showUFData?: boolean;
    showSubsidyData?: boolean;
    geoApartmentLabel: string;
    navigation: { name: string; href: string }[];
  }
> = Object.freeze({
  CL: {
    href: '/',
    code: 'CL',
    navBarTitle: 'CL',
    countryFilterValue: 'Chile',
    selected: false,
    showCapitalGain: true,
    showDividendCalculator: true,
    showUFData: true,
    showSubsidyData: true,
    geoApartmentLabel: 'Comuna',
    navigation: [
      { name: 'Vender', href: '/vende' },
      { name: 'Comprar', href: '/compra' },
      { name: 'Crédito', href: '/credito' },
      { name: 'Tracking', href: '/tracking' },
      { name: '¿Quienes somos?', href: '/quienes-somos' },
      { name: 'Brokers', href: '/brokers' },
    ],
  },
  CO: {
    href: '/co',
    code: 'CO',
    navBarTitle: 'COL',
    countryFilterValue: 'Colombia',
    geoApartmentLabel: 'Barrio',
    selected: false,
    navigation: [{ name: '¿Quienes somos?', href: '/quienes-somos' }],
  },
  MX: {
    href: '/mx',
    code: 'MX',
    navBarTitle: 'MX',
    countryFilterValue: 'Mexico',
    geoApartmentLabel: 'Distrito',
    selected: false,
    navigation: [],
  },
});

export const DEFAULT_COUNTRY_CODE = COUNTRY_CONFIGS.CL.code;

export const COUNTRY_COOKIE_EXPIRATION = 60 * 60 * 24 * 30; // 30 days

export const GOB_LINK =
  'https://accounts.claveunica.gob.cl/accounts/login/?next=/openid/authorize/%3Fclient_id%3D495c261c0f484bd08e232f7358452c4d%26redirect_uri%3Dhttp%253A%252F%252Fwww.svs.cl%252Fsitio%252Fclave_unica%252Fclave_unica.php%253Fapp%253Dinf_deuda_d%26response_type%3Dcode%26scope%3Dopenid+run+name+email%26state%3Da6e0f1caa8fd1190a9991a4947d655f3&t=1614179988';

export const FILTERS_ZONE = [
  {
    label: 'Bancos',
    type: 'bank',
  },
  {
    label: 'Salud',
    url: 'hospital',
  },
  {
    label: 'Tiendas',
    url: 'store',
  },
  {
    label: 'Transporte',
    url: 'transit_station',
  },
  {
    label: 'Restaurantes',
    url: 'restaurant',
  },
  {
    label: 'Educación',
    url: 'school',
  },
];

export const ADDRESS_FLAT_REGEX =
  /(DP|DPTO|DPA|DPB|DPC|DPD|DPE|dpto\.|DEPTO\.|DEPARTAMENTO)\s+(\d+)/i;

export const STOCK_ADDITIONAL_INFO_ROOMS: Record<string, JSX.Element> = {
  bathroom: <IconBathroom width={20} height={20} />,
  bedroom: <IconBedroom width={20} height={20} />,
  living: <IconLivingRoom width={20} height={20} />,
  kitchen: <IconKitchen width={20} height={20} />,
};
export const dayHours = [
  {
    id: 1,
    hour: '09:00',
    available: true,
  },
  {
    id: 2,
    hour: '10:00',
    available: true,
  },
  {
    id: 3,
    hour: '11:00',
    available: true,
  },
  {
    id: 4,
    hour: '12:00',
    available: true,
  },
  {
    id: 5,
    hour: '13:00',
    available: true,
  },
  {
    id: 6,
    hour: '14:00',
    available: true,
  },
  {
    id: 7,
    hour: '15:00',
    available: true,
  },
  {
    id: 8,
    hour: '16:00',
    available: true,
  },
  {
    id: 19,
    hour: '17:00',
    available: true,
  },
  {
    id: 10,
    hour: '18:00',
    available: true,
  },
  // {
  //   id: 11,
  //   hour: '19:00',
  //   available: true,
  // },
  // {
  //   id: 12,
  //   hour: '20:00',
  //   available: true,
  // },
];
